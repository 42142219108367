import { Application } from "@evento/application/utils";
import { Layout as CalendarLayout, Provider as CalendarProvider } from "@evento/calendar";
import calendarPages from "@evento/calendar/pages";
import { Provider } from "@evento/ucme-se-prezit-components";
import { Layout, HomepageLayout } from "@evento/ucme-se-prezit-components/layouts";
import pages from "@evento/ucme-se-prezit-components/pages";

import "@base/styles";

const app = new Application()
    .setPages({
        default: pages,
        calendar: calendarPages,
    })
    .setPageResolver(async (name, pages, resolve) => {
        if (name.startsWith("Calendar/")) {
            return await resolve(pages.calendar, name);
        }

        return await resolve(pages.default, name);
    })
    .setLayouts({
        default: [Provider, Layout],
        homepage: [Provider, HomepageLayout],
        calendar: [Provider, CalendarProvider, CalendarLayout],
    })
    .setLayoutResolver(async (name, layouts, resolve) => {
        if (name.startsWith("Calendar/")) {
            return await resolve(layouts.calendar);
        }

        if (name === "Homepage" || name.startsWith("Campaign/")) {
            return await resolve(layouts.homepage);
        }

        return await resolve(layouts.default);
    })
    .setColor("#e6b700")
    .setDebug(import.meta.env.VITE_DEBUG);

app.run();
